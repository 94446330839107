<template>
  <div>
    <div id="departments-page">
      <h1 class="h1-title">Departments</h1>
      <div class="flex-row margin-top-2">
        <secondary-button
          title="Add new department"
          icon="/assets/img/icon-plus.svg"
          class="min-width-192 margin-right-05"
          :disabled="selectedDepartments.length > 0"
          v-on:click="selectedDepartments.length > 0 ? () => {} : (() => (addingDepartment = true))()"
        />
        <search-bar
          class="margin-right flex-grow"
          availableFilterMode="departments"
          placeholder="Search for name, phone number or email"
          :emitActions="true"
          @applyFilter="applyFilter"
        >
          <secondary-button
            icon="/assets/img/icon-pencil.svg"
            title="Edit"
            class="min-width-120 margin-left-05"
            :disabled="!(selectedDepartments.length == 1)"
            v-on:click="editSelectedDepartment()"
          />
        </search-bar>
      </div>
      <filter-bar :customFilters="filters" @removeFilter="removeFilter" />

      <div class="results-table-container margin-top">
        <table class="results-table">
          <tr class="header">
            <th>
              <input v-model="selectAllCheckbox" type="checkbox" name="" id="" />
            </th>
            <th>Department Name</th>
            <th>Dealership</th>
          </tr>
          <tr
            v-for="department in filteredDepartments().sort((a, b) => a.title - b.title)"
            v-bind:key="department.id"
            class="data-row"
          >
            <td>
              <input type="checkbox" name="" id="" v-model="selectedDepartments" :value="department" />
            </td>
            <td>{{ department.title }}</td>
            <td class="dealership">{{ dealershipName(department.dealership) }}</td>
          </tr>
        </table>
      </div>

      <new-department-modal
        v-if="addingDepartment"
        @createDepartment="callCreateDepartment"
        @cancel="addingDepartment = null"
      />
      <new-department-modal
        v-if="selectedDepartment"
        :editingDepartment="selectedDepartment"
        @createDepartment="callUpdateDepartment"
        @cancel="selectedDepartment = null"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NewDepartmentModal from "../../components/Modals/v2/NewDepartmentModal.vue";

import SearchBar from "../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../components/Views/v2/FilterBar.vue";
import SecondaryButton from "../../components/Buttons/v2/SecondaryButton.vue";
import _ from "lodash";

export default {
  name: "Departments",
  components: {
    NewDepartmentModal,
    SecondaryButton,
    SearchBar,
    FilterBar
  },
  data: function () {
    return {
      selectAllCheckbox: false,
      addingDepartment: false,
      selectedDepartment: null,
      selectedDepartments: [],
      filters: {},
      error: null
    };
  },
  methods: {
    filteredDepartments: function () {
      var toReturn = this.departments;
      if ("quick_search" in this.filters) {
        var quickSearch = this.filters["quick_search"];
        if (quickSearch != "") {
          toReturn = toReturn.filter(department => {
            if (!department.title.toLowerCase().includes(quickSearch.toLowerCase())) {
              return false;
            }

            return true;
          });
        }
      }
      if ("dealership" in this.filters) {
        toReturn = toReturn.filter(department => department.dealership == this.filters["dealership"]);
      }
      if ("dealership_not" in this.filters) {
        toReturn = toReturn.filter(department => department.dealership != this.filters["dealership_not"]);
      }
      return toReturn;
    },
    dealershipName: function (id) {
      let dealership = this.dealershipWithId(id);
      if (dealership) {
        return dealership.first_name;
      } else {
        return "-";
      }
    },
    callCreateDepartment: function (department) {
      this.createDepartment(department);
      this.addingDepartment = false;
    },
    callUpdateDepartment: function (department) {
      this.updateDepartment({
        department: this.selectedDepartment,
        title: department.title
      });
      this.selectedDepartment = null;
      this.selectedDepartments = [];
    },
    editSelectedDepartment: function () {
      this.selectedDepartment = _.cloneDeep(this.selectedDepartments[0]);
    },
    applyFilter: function (filter) {
      var newFilters = _.cloneDeep(this.filters);
      newFilters[filter.filter.key] = filter.filter.value;
      this.filters = newFilters;
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    ...mapActions({
      getDealerships: "dealershipStore/fetchDealerships",
      createDepartment: "dealershipStore/createDepartment",
      updateDepartment: "dealershipStore/updateDepartment",
      deleteDepartment: "dealershipStore/deleteDepartment"
    })
  },
  watch: {
    selectAllCheckbox: function (newVal) {
      if (newVal) {
        this.selectedDepartments = this.departments;
      } else {
        this.selectedDepartments = [];
      }
    }
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships",
      dealershipWithId: "dealershipStore/dealershipWithId",
      departments: "dealershipStore/departments"
    })
  },
  mounted: function () {
    this.getDealerships();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#departments-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
