<template>
  <base-modal-view :title="editingDepartment ? 'Edit Department' : 'Add Department'" @cancel="cancelClicked">
    <form class="flex-column" @submit.prevent="submitClicked">
      <p class="no-margin margin-top label" v-if="!editingDepartment">Dealership</p>
      <select class="margin-top-025 dropdown-field" required v-model="department.dealership" v-if="!editingDepartment">
        <option value="">-- Select a Dealership --</option>
        <option v-for="dealership in dealerships" v-bind:key="dealership.id" :value="dealership.id">
          {{ dealership.first_name }}
        </option>
      </select>
      <p class="no-margin margin-top label">Name</p>
      <input v-model="department.title" type="text" class="margin-top-025 text-field" placeholder="Name" />
      <primary-button class="margin-top-1-05" title="Submit" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

import { mapGetters } from "vuex";

export default {
  name: "NewDepartmentModal",
  components: { PrimaryButton, BaseModalView },
  props: ["editingDepartment"],
  data: function () {
    return {
      department: {
        title: "",
        dealership: ""
      }
    };
  },
  methods: {
    submitClicked: function () {
      this.$emit("createDepartment", this.department);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  computed: {
    ...mapGetters({
      dealerships: "dealershipStore/dealerships"
    })
  },
  mounted: function () {
    if (this.editingDepartment) {
      this.department = this.editingDepartment;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
